import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { GetSinglepatient } from '../Services/PatientServicces';
import { GetPrescription } from '../Services/TestServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import Female from '../assets/female-Pofile.jpg';
import Male from '../assets/Male.jpg';
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,Box, Typography, Dialog, DialogTitle, DialogContent, Slide, Checkbox, TextField, Button } from '@mui/material';
import SpinnerModal from '../Spinner/SpinnerModal';
import moment from 'moment';
import { Reports,Generate_Reports,GetDoctorInfo,GetFontStyles,GetStaticResult } from '../Services/TestServices';
import PrintReports from './PrintReports';
import { useReactToPrint } from 'react-to-print';
import { useApi } from '../ContextApi/ContextApi';
import { toast } from 'react-toastify';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import '../UI/PatientProfile.css';




const PatientProfile = () => {
  const[info,SetInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [ProfileData, setProfileData] = useState({});
  const [PrescriptionData, setPrescriptionData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const { isReportGenerate, setReport_generation_SubmissionStatus } = useApi();
  const [selectedTests, setSelectedTests] = useState([]);
  const[Fonts,SetFonts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const componentRef = useRef();
  const { id } = useParams();
  const inputRefs = useRef([]);
  const [Comment,setComment] = useState('');
  const [QrCodeURl,SetQRCodeUrl] = useState([]);
  const [commentVisibility, setCommentVisibility] = useState({});
  const [STATICRESULTS,SETRESULTS] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [groupedTests, setGroupedTests] = useState({});
  const [sortedOrderIDs, setSortedOrderIDs] = useState([]);
  const [isActive, setisActive] = useState(true);

  sessionStorage.setItem('PatientID', id);

  const icon = ProfileData?.[0]?.sex === "male" ? <ManIcon className='genderIcon'/> : <WomanIcon className='genderIcon'/>;
//---------------------------------getting profile Data----------
useEffect(() => {
  GetprofileData();
}, [id]);


  const GetprofileData = async () => {
    setIsLoading(true);
    try {
      const LabID = sessionStorage.getItem('LabID');
      const data = await GetSinglepatient(id, LabID);
      setProfileData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
//----------------------------print functionality---------------------
  useEffect(() => {
    if (selectedTests.length > 0) {
      PrintReport();
    }
  }, [selectedTests]);
//------------------------print call -------------------------------
  const print = useReactToPrint({
    content: () => componentRef.current,
  });
//--------------------------------Getting Print Report data-----------------
const PrintReport = async () => {
  setIsLoading(true);
  try {
    const patientID = sessionStorage.getItem('PatientID');
    const LabID = sessionStorage.getItem('LabID');
    const IDs = selectedTests.map(x => x.labOrderID).join('&SelectedIDs=');
const Url = `https://securelab.api.sehhasolutions.com/api/Home/QRCodeData?PatientID=${patientID}&LabID=${LabID}&SelectedIDs=${IDs}`;
SetQRCodeUrl(Url);
    const data = await Reports(patientID, LabID,selectedTests);
    setReportData(data);
  } catch (error) {
    console.error('Failed to fetch reports:', error);
  } finally {
    setIsLoading(false);
  }
};

//-------------------END-----------------
//------------------calling all functions which should be called in render------------
  useEffect(() => {
    GetPresciptionData();
    setReport_generation_SubmissionStatus(false);
  }, [id,isReportGenerate]);
//-------------------------------Getting Prescription Data-------------------
  const GetPresciptionData = async () => {
    const LabID = sessionStorage.getItem('LabID');
    const response = await GetPrescription(id, LabID);
    setPrescriptionData(response);
  };

  //---------------------------handle checkbox-----------------

  const handleCheckboxChange = (testID, labOrderID) => {
     const LabID = sessionStorage.getItem('LabID');
     const patientID = sessionStorage.getItem('PatientID');
     const newTest = { LabID, patientID, testID ,labOrderID};
    setSelectedTests(prevSelectedTests => {
      const testExists = prevSelectedTests.some(test => test.testID === testID && test.labOrderID === labOrderID);
  
      if (testExists) {
        return prevSelectedTests.filter(test => !(test.testID === testID && test.labOrderID === labOrderID));
      } else {
        return  [...prevSelectedTests, newTest]
      }
    });
  };
  
//----------------------END-------------------------------
//------------------------Handle select All test--------------
const handleSelectAllChange = (event, orderID, categoryvalue) => {
  const checked = event.target.checked;

  setSelectAll((prev) => ({
    ...prev,
    [`${orderID}-${categoryvalue}`]: checked
  }));

  setSelectedTests((prevSelectedTests) => {
    if (checked) {
      // Get all tests for the selected category
      const allTests = PrescriptionData
        .filter(test => 
          test.testResult && 
          parseInt(orderID, 10) === test.orderID &&
          test.categorY_VALUE === categoryvalue // Ensure category match
        )
        .map(test => ({ 
          LabID: sessionStorage.getItem('LabID'),
          patientID: sessionStorage.getItem('PatientID'),
          testID: test.testID,
          labOrderID: test.labOrderid,
          categorY_VALUE: categoryvalue,
        }));

      // Merge existing selected tests with the new ones (avoid duplicates)
      return [...prevSelectedTests, ...allTests].filter(
        (test, index, self) =>
          index === self.findIndex((t) => t.testID === test.testID && t.labOrderID === test.labOrderID)
      );
    } else {
      return prevSelectedTests.filter(
        (test) => test.categorY_VALUE !== categoryvalue
      );
      
    }
  });
};


//---------------------------END----------------------
//======================= Group data by date and time========
useEffect(() => {
  const grouped = PrescriptionData.reduce((acc, test) => {
    if (!acc[test.orderID]) {
      acc[test.orderID] = {};
    }
    if (!acc[test.orderID][test.categorY_VALUE]) {
      acc[test.orderID][test.categorY_VALUE] = [];
    }
    acc[test.orderID][test.categorY_VALUE].push(test);
    Object.keys(acc[test.orderID]).forEach((category) => {
      acc[test.orderID][category].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    });
    return acc;
  }, {});

  // Get and sort OrderIDs in descending order
  const orderIDs = Object.keys(grouped)
    .map((id) => parseInt(id)) 
    .sort((a, b) => b - a);

  setGroupedTests(grouped);
  setSortedOrderIDs(orderIDs);
  
  if(isActive && orderIDs.length > 0){
      setActiveTab(orderIDs[0] || null);
       setisActive(false);
  }
}, [PrescriptionData]);

//=============END=====================================

  //--------------------------handle result change and keydown and  save----------------------
  // const handleKeyDown = (event, testID,LabOrderID, index) => {
  //   if (event.key === 'Enter') {
  //     event.preventDefault(); 
  //     handleSave(testID,LabOrderID, index);
  //   }
  // };
  
  const handleKeyDown = (event, testID, LabOrderID, index,categoryCode) => {
    if (event.key === 'Enter') {
      event.preventDefault();
  
      const currentKey = `${index}_${categoryCode}`;
      handleSave(testID, LabOrderID,  currentKey);
    }
  };
  
  const handleInputChange = (testID, LabOrderID,value ) => {
    setPrescriptionData((prevPrescriptionData) =>
      prevPrescriptionData.map(test =>
        (test.testID === testID && test.labOrderid === LabOrderID )? { ...test, labOrderID_In_GR: LabOrderID, testResult: value } : test
      )
    );
  };
  
  const handleSave = async (testID,LabOrderID,currentKey) => {
    setIsLoading(true);
    try {
      const testToSave = PrescriptionData.find(test => test.testID === testID && test.labOrderid === LabOrderID);
      const LabID = sessionStorage.getItem('LabID');
      const patientID = sessionStorage.getItem('PatientID');

      // Include patient ID and laboratory ID when saving data
        const savedData = {
          ...testToSave,
          LabID,
          patientID,
          reportID: testToSave.reportID || 0,
          Comment: testToSave.comment
        };
     if(savedData.testResult == '' || savedData.testResult == null){
          toast.error("Result required")
     }else{
      const Response = await Generate_Reports(savedData);
      if (Response) {
        toast.success("Report Generated Successfully");
        setReport_generation_SubmissionStatus(true);
        
      
        const keys = Object.keys(inputRefs.current);
    const currentIndex = keys.indexOf(currentKey);

    if (currentIndex !== -1 && keys[currentIndex + 1]) {
      inputRefs.current[keys[currentIndex + 1]].focus();
    }
    }
  }
    } catch (error) {
      toast.error("Error occurred while saving data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  //---------------------------End-----------------------

 //---------------Formate normal range and unit value -- function 
 const formatTextWithLineBreaks = (text) => {
  return text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </React.Fragment>
  ));
};

//---------------END ----------------
//---------------Get Doctor Info-------------
const DoctorInfo = async () =>{
  setIsLoading(true)
  try{
   
    const LabID = sessionStorage.getItem('LabID');
    const response  =await GetDoctorInfo(LabID);
    if(response != null){
      SetInfo(response)
    }
  }catch(error){
    toast.error(error)
  }finally{
    setIsLoading(false)
  }
}
//----------------End--------
//--------------Get Font Styles-----------
const Fontstyle = async () =>{
  setIsLoading(true)
  try{
   
    const LabID = sessionStorage.getItem('LabID');
    const response  =await GetFontStyles(LabID);
    if(response != null){
      SetFonts(response)
    }
  }catch(error){
    toast.error(error)
  }finally{
    setIsLoading(false)
  }
}
useEffect(()=>{
  DoctorInfo();
  Fontstyle();
},[])
//------------End-------------------
//-------------Add-Comment-------------
const OnchangeComment = (TestID,OrderID,Value) =>{
  setPrescriptionData((prevPrescriptionData) =>
    prevPrescriptionData.map(test =>
      (test.testID === TestID && test.labOrderid === OrderID )? { ...test, comment: Value } : test
    )
  );
}
//-----------------END---------
//-------comment show and hide 
const toggleCommentVisibility = (testID, labOrderID) => {
  setCommentVisibility(prev => ({
    ...prev,
    [`${testID}-${labOrderID}`]: !prev[`${testID}-${labOrderID}`]
  }));
};
//--------end-----------
const STATICRESULT = async () =>{
  try{
    const LabID = sessionStorage.getItem('LabID');
    const response = await GetStaticResult(LabID);
    SETRESULTS(response);
  }
  catch(error){
     console.log("Error getting Static Result",error)
  }
}
useEffect(()=>{
  STATICRESULT();
},[])
//-------------------handle tab change----------
 
  const handleTabClick = (orderID) => {
    setActiveTab(orderID);
  };

  return (
    <>
      {isLoading && <SpinnerModal isLoading={isLoading} />}
      <div style={{ display: 'none' }}>
        <PrintReports reportData={reportData} DRInfos={info} Fonts={Fonts} QrCodeURL={QrCodeURl} ref={componentRef} />
      </div>
      <div  className='Container2' style={{padding:'0px 12px'}}>
        <div className='Common-min-height'>
        <div className='p-1 Profile-header common-box-shadow common-bg-white common-br-3px'>
          <div className='row'>
                  <div className='col-sm-3 d-flex'>
                    <div  className='Pics-div'>
                       {ProfileData?.[0]?.sex === "male" ? (<img className='img-profile' src={Male} alt="Male Profile" />) : (<img className='img-profile' src={Female} alt="Female Profile" />)}
                   </div> 
                   <div className='common-line-height ml-2' style={{    marginLeft: '6px',textAlign:'start'}}>
                      <span className='commont-Font-Bold common-FontSize-small'>{ProfileData?.[0]?.patientName.toUpperCase()}</span><br></br>
                      <span className='common-Font-10'>{ProfileData?.[0]?.sex} - {ProfileData?.[0]?.age} Year</span><br></br>
                      <span className='common-Font-10'>MR No: {ProfileData?.[0]?.patientID}-{sessionStorage.getItem('LabID')}</span><br></br>
                   </div>
                  </div>
              
                  <div className='col-sm-2' style={{marginLeft:'10px'}} >
                    <div>
                        <div style={{textAlign:'start'}}>
                          <span className='common-FontSize-small common-color'><PhoneIphoneOutlinedIcon  className='phone-icon'/> Phon No</span><br></br>
                          <span style={{marginLeft:'10px'}} className='common-FontSize-small'> {ProfileData?.[0]?.contactNumber}</span>
                        </div>
                    </div>
                   
                            
                  </div>
                   <div className='col-sm-3' >
                     <div style={{textAlign:'start'}}>
                        <span className='common-FontSize-small common-color'><CalendarMonthRoundedIcon  className='ProfileIcon'/>Registered Date</span><br></br>
                        <span className='common-FontSize-small'>{moment(ProfileData?.[0]?.created_at).format('YYYY-MM-DD')}</span>
                      </div>
                    </div>
                    <div className='col-sm-3' >
                     <div style={{textAlign:'start'}}>
                        <span className='common-FontSize-small common-color'><LocationOnIcon  className='ProfileIcon'/> Address </span> <br></br>
                        <span className='common-FontSize-small'>{ProfileData?.[0]?.address}</span>
                      </div>
                    </div>
          </div>
           
        </div>
        <div style={{textAlign:'end',marginBottom:'3px'}}>
                <button disabled={!selectedTests.length > 0} className='printButton'  onClick={print} >
                  <PrintRoundedIcon /> Print
                  </button>
            </div>
        <div>
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%',gap:'10px' }}>
              {/* Left side for tabs */}
              <div className='profile-vertical-parent common-box-shadow common-bg-white common-br-3px'>
               
                <div className="profile-vertical-tabs common-FontSize-small">
                  <div>
                    <span>Orders</span>
                    <hr style={{margin:0}}></hr>
                  </div>
                  <div className="nav flex-column nav-pills" role="tablist">
                  {Object.keys(groupedTests)
                    .sort((a, b) => Number(b) - Number(a)) 
                    .map((orderID) => {
                      const mainCategories = Object.keys(groupedTests[orderID]); 
                      const firstTest = groupedTests[orderID][mainCategories[0]]?.[0];
                      return (
                        <a
                          key={orderID}
                          className={`nav-link ${activeTab === orderID ? 'profile-tab-active' : 'profile-tab'}`}
                          onClick={() => handleTabClick(orderID)}
                          style={{ cursor: 'pointer' }}
                        >
                          {/* Order date and status */}
                          <span className='common-Font-10'>
                            {moment(firstTest?.createdAt).format('DD/MM/YY')} | Order Sent
                          </span>
                          <br />

                          {/* Patient name */}
                          <span className='common-Font-10'>{firstTest?.patientName}</span>
                          <br />

                          {/* Display the main categories */}
                          {mainCategories.map((category, index) => (
                            <span key={index} className='common-Font-10'>
                              {index > 0 && ', '}
                              {category}
                            </span>
                          ))}
                        </a>
                      );
                    })}
                  </div>

                </div>
              </div>

              {/* Right side for the content of selected tab */}
              <div className="profile-Tabs-content common-box-shadow common-bg-white common-br-3px">
               {(activeTab && groupedTests) && (
                    <>
                      <div className="details-heading">
                        <span>Order id: {activeTab}</span>
                        <span style={{ marginLeft: '10px' }}>
                            Order on: {  moment(Object.values(groupedTests[activeTab])?.[0]?.[0]?.createdAt).format('YYYY-MM-DD')}
                          </span>
                      </div>
                      {Object.keys(groupedTests[activeTab]).map((mainCategory, idx) => (
                        <div key={idx}>
                          <h4 className="common-FontSize-small">{mainCategory}</h4>
                          <table className="table common-FontSize-small">
                            <thead>
                              <tr>
                                <th className="profile-th">
                                  <Checkbox
                                    checked={selectAll[`${activeTab}-${mainCategory}`] || false}
                                    onChange={(event) => handleSelectAllChange(event, activeTab, mainCategory)}
                                    sx={{
                                      color: '#2c6bd7',
                                      padding: '0px',
                                      '&.Mui-checked': { color: '#2c6bd7', padding: '0px' },
                                      width: '20px',
                                      height: '20px'
                                    }}
                                  />
                                </th>
                                <th className="profile-th">Test Name</th>
                                <th className="profile-th">Normal Range</th>
                                <th className="profile-th">Result</th>
                                <th className="profile-th">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {groupedTests[activeTab][mainCategory].map((data, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td className="common-FontSize-small gray profile-td">
                                      <Checkbox
                                        disabled={!data.testResult}
                                        sx={{ color: '#2c6bd7', '&.Mui-checked': { color: '#2c6bd7' }, padding: '0px' }}
                                        checked={selectedTests.some(
                                          (test) => test.testID === data.testID && test.labOrderID === data.labOrderid
                                        )}
                                        onChange={() => handleCheckboxChange(data.testID, data.labOrderid)}
                                      />
                                    </td>
                                    <td className="common-FontSize-small profile-td">{data.testName}</td>
                                    <td className="common-FontSize-small profile-td">
                                      {formatTextWithLineBreaks(data.normalRange)}
                                    </td>
                                    <td className="common-FontSize-small profile-td">
                                      <input
                                        required
                                        className={`result-Textbox ${data.testResult ? 'green-background' : ''}`}
                                        value={data.testResult || ''}
                                        onChange={(e) => handleInputChange(data.testID, data.labOrderid, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, data.testID, data.labOrderid, index,data.categorY_CODE)}
                                        ref={(el) => (inputRefs.current[`${index}_${data.categorY_CODE}`] = el)}
                                        list="Suggestion"
                                      />
                                      <datalist id="Suggestion">
                                        <option value="Nill" />
                                        <option value="Positive" />
                                        <option value="Negative" />
                                        {STATICRESULTS.map((RS, index) => (
                                          <option key={index} value={RS.sResult} />
                                        ))}
                                      </datalist>
                                    </td>
                                    <td className="common-FontSize-small profile-td">
                                      {data.reportID ? (
                                        <button
                                          disabled={!data.testResult}
                                          className="Result-Updatebutton"
                                          size="small"
                                          onClick={() => handleSave(data.testID, data.labOrderid, index)}
                                        >
                                          Update
                                        </button>
                                      ) : (
                                        <button
                                          disabled={!data.testResult}
                                          className="Result-Savebutton"
                                          size="small"
                                          onClick={() => handleSave(data.testID, data.labOrderid, index)}
                                        >
                                          Save
                                        </button>
                                      )}
                                      <button
                                        type="button"
                                        className="Comment-button"
                                        onClick={() => toggleCommentVisibility(data.testID, data.labOrderid)}
                                      >
                                        Comment
                                      </button>
                                    </td>
                                  </tr>
                                  {commentVisibility[`${data.testID}-${data.labOrderid}`] && (
                                    <tr>
                                      <td colSpan="5">
                                        <textarea
                                          value={data.comment}
                                          onChange={(e) => OnchangeComment(data.testID, data.labOrderid, e.target.value)}
                                          className="form-control common-Input"
                                          placeholder="Add comment here"
                                        />
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ))}

                    </>
                  )}
                </div>

            </div>
        </div> 
        </div>
        
      </div>
       
         
 
    </>
  );
};

export default PatientProfile;
