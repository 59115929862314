import React from 'react'
import { useNavigate } from 'react-router-dom';
import  {useCallback, useState,useRef} from 'react'
import { PatientRegistration_LabOrders } from '../Services/PatientServicces';
import { Card , Divider ,Button } from '@mui/material';
//   import SendIcon from '@mui/icons-material/Send';
import "../UI/patientRegistration.css"
import SpinnerModal from '../Spinner/SpinnerModal';
import LabOrder from './LabOrder';
import { toast } from 'react-toastify';
import '../UI/Common.css'


const PatientRegistration = () =>{
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false); 
  const [IsLaBOrder,setisLabOrder] = useState(false);
  const [LabOrderData, setlabOrderData] = useState({})

    const[formData,setformData] = useState({
        PatientName:'',
        ReferredBy:'',
        ContactNumber:'',
        gender:'male',
        Age:'',
        Comments :'',
        DOB:'',
        Address:'',
        LaboratoryID: sessionStorage.getItem('LabID')
    });
    
    const onchange = (e) =>{
        const {name,value} = e.target;
        setformData({
            ...formData,
            [name]:value,
        }) 
    }


    const handleOrderFormSubmit = useCallback((orderFormData) => {
      if (orderFormData) {
        setisLabOrder( true);
        setlabOrderData(orderFormData);
      } else {
        setisLabOrder( false);
      }
    }, []);
    

   
  const SubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
        if(LabOrderData.length > 0){
          const response = await PatientRegistration_LabOrders(formData, LabOrderData);
          if (response) {
            toast.success('Patient added successfully');
            navigate('/Home/PatientList')
          }
        }else{
          toast.info("Please add test")
        }
    } catch (error) {
      console.log('Something went wrong:', error);
      toast.error('Something went wrong while adding patient');
    } finally {
      setIsLoading(false);
    }
  };
    
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission if inside a form
      SubmitForm();
    }
  };

    const Gender = [
        {
          value: 'male',
          label: 'Male',
        },
        {
          value: 'female',
          label: 'Female',
        },
       
      ];
        //-----------------------enter
        const inputRefs = useRef([]);

        const handleKeyDownEnter = (e, index) => {
            if (e.key === "Enter") {
                e.preventDefault(); // Prevent form submission
                if (inputRefs.current[index + 1]) {
                    inputRefs.current[index + 1].focus();
                }
            }
        };
        
    return (
    <>  
  {isLoading && <SpinnerModal isLoading={true} />}
   <div className='container-fluid Container2'>
      <div className='child-container-pr Common-min-height common-box-shadow common-bg-white common-br-3px'>
        <form   onSubmit={SubmitForm}>
            <div className='row'>
              <div className='d-flex justify-content-between'>
                <h5 className='common-FontWeight common-FontFamily'>Patient Registration</h5>
               
              </div>
                <div  className='col-sm-3'>
                <label className='common-Labels' for="PatientName">Patient Name <span className='common-Asterisk'>*</span></label>
                 <input
                        className="form-control common-Input"
                        id='PatientName'
                        name='PatientName'
                        size='small'
                        required
                        onChange={onchange}
                        ref={(el) => (inputRefs.current[0] = el)}
                        onKeyDown={(e) => handleKeyDownEnter(e, 0)}
                    ></input>
                </div>
                <div className='col-sm-3'>
                <label className='common-Labels' for="ReferredBy">ReferredBy <span className='common-Asterisk'>*</span></label>
                        <input
                        className="form-control common-Input"
                        id='ReferredBy'
                        size='small'
                        name='ReferredBy'
                        required
                        onChange={onchange} 
                        ref={(el) => (inputRefs.current[1] = el)}
                        onKeyDown={(e) => handleKeyDownEnter(e, 1)}
                        />
                        
                </div>
              <div  className='col-sm-3'>
              <label className='common-Labels' for="ContactNumber">Contact Number</label>
                        <input
                            className="form-control common-Input"
                          id='ContactNumber'
                          name='ContactNumber'
                          size='small'
                         onChange={onchange}
                         ref={(el) => (inputRefs.current[2] = el)}
                         onKeyDown={(e) => handleKeyDownEnter(e, 2)}
                         />
                         
                  </div> 
                
                <div  className='col-sm-3'>
                <label className='common-Labels' for="gender">Gender</label><br></br>
                <select
                   className="form-control common-Input"
                   id='gender'
                   name='gender'
                   select
                   size='small'
                   required
                  onChange={onchange}
                  ref={(el) => (inputRefs.current[3] = el)}
                   onKeyDown={(e) => handleKeyDownEnter(e, 3)}
                 >
                  {Gender.map((option) => (
                 <option key={option.value} value={option.value}>
                   {option.label}
                 </option>
                ))}
               </select>
                   
                </div>
                <div  className='col-sm-3'>
                <label className='common-Labels' for="Age">Age</label>
                        <input
                          className="form-control common-Input"
                          id='Age'
                          name='Age'
                          size='small'
                         onChange={onchange} 
                         ref={(el) => (inputRefs.current[4] = el)}
                         onKeyDown={(e) => handleKeyDownEnter(e, 4)}
                         
                         />
                        
                  </div>
                
                  <div  className='col-sm-3'>
                  <label className='common-Labels' for="Comments">Comments</label>
                        <input
                          className="form-control common-Input"
                          id='Comments'
                          name='Comments'
                          size='small'
                         onChange={onchange} 
                         ref={(el) => (inputRefs.current[5] = el)}
                         onKeyDown={(e) => handleKeyDownEnter(e, 5)}
                         />
                         
                  </div>
                 
                 <div  className='col-sm-3'>
                 <label className='common-Labels' for="DOB">DOB</label>
                        <input
                          className="form-control common-Input"
                          id='DOB'
                          name='DOB'
                          size='small'
                         onChange={onchange} 
                         
                         ref={(el) => (inputRefs.current[6] = el)}
                         onKeyDown={(e) => handleKeyDownEnter(e, 6)}
                         />
                        
                  </div>
                  <div  className='col-sm-3'>
                  <label className='common-Labels' for="Address">Address</label>
                        <input
                          className="form-control common-Input"
                          id='Address'
                          name='Address'
                          size='small'
                         onChange={onchange}
                         ref={(el) => (inputRefs.current[7] = el)}
                         onKeyDown={(e) => handleKeyDownEnter(e, 7)}
                         />
                        
                  </div>
                 </div>
                 
                <Divider sx={{color:'black',marginTop:'1rem'}}></Divider>
                <div className='row'>
                   <LabOrder   onSubmit={handleOrderFormSubmit}/>
                </div>
                <Button onKeyDown={(e) => handleKeyDown}  type='submit'   variant="contained" className="common-button mt-3" style={{float:'inline-end'}}>Save</Button>
                 
                  
        </form>
        </div>  
    </div>
    </>
        
    )
}
export default PatientRegistration;