import React from 'react'
import  {useEffect, useState,useCallback} from 'react'
import { RetrivePatientsData,DeletePatient,PatientRegistration_LabOrders,GloabalSearchPatient } from '../Services/PatientServicces';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import {
    TableCell,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
  } from '@mui/material';
  import ButtonGroup from '@mui/material/ButtonGroup';
  import Button from '@mui/material/Button';
import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Slide from '@mui/material/Slide';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useNavigate } from 'react-router-dom';
import SpinnerModal from '../Spinner/SpinnerModal';
import { useApi } from '../ContextApi/ContextApi';
import { styled } from '@mui/material/styles';
import  { tableCellClasses } from '@mui/material/TableCell';
import LibraryAddRoundedIcon from '@mui/icons-material/LibraryAddRounded';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import Delete from '../assets/trash.svg'
import Plus from '../assets/add.svg'

import '../UI/PatientList.css'
import LabOrder from './LabOrder';

  const Transition = React.forwardRef(function Transition(props, ref) {
    const transitionDuration = 500; // milliseconds
    return <Slide direction="down" ref={ref} {...props} timeout={transitionDuration} />;
  });
const PatientList = () =>{
    const { isSubmitted ,setSubmissionStatus} = useApi();
    const [PatientData,setPatientData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const[isPateintID,SetPatientID] = useState([]);
    const [isDelete,SetisDelete] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [LabOrderData, setlabOrderData] = useState({});
    const [IsLaBOrder,setisLabOrder] = useState(false);
    const [ModalHeadrData,SetModalHeaderData] = useState({
      patientName:'',
      age:'',
      Sex:''
    });
    const[formData,setformData] = useState({
      PatientID:'',
      PatientName:'',
      ReferredBy:'',
      ContactNumber:'',
      gender:'male',
      Age:'',
      Comments :'',
      DOB:'',
      Address:'',
      LaboratoryID: sessionStorage.getItem('LabID')
  });
    const navigate = useNavigate();



    const GettignData = async ()=>{
     try{
        setIsLoading(true)
         const LabID = sessionStorage.getItem('LabID')
         const Response = await RetrivePatientsData(LabID);
         setPatientData(Response)
         setSubmissionStatus(false)
        }
        catch(eror){
            console.log(eror)
        }finally{
            setIsLoading(false)
        }
     }

    useEffect(() => {
        GettignData();
    },[isSubmitted])
     

////--------------open profile------------

const NavigatetoProfile = (id) =>{
    navigate(`/Home/PatientProfile/${id}`)
}
//------------end---------------
//-----------Delete Pateint--------
const handleDelete = (id) =>{
  SetPatientID(id);
  if(id){
    SetisDelete(true)
   }else{
    SetisDelete(false)
  }
}


 const DeletePatients = () =>{
   try{
    setIsLoading(true)
       const LabID = sessionStorage.getItem('LabID');
        var res = DeletePatient(LabID,isPateintID)   
        if(res){
          toast.success("Patient Deleted!")
          setSubmissionStatus(true)
        }   
   }
   catch(error){
       toast.error(error)
   }finally{
     SetisDelete(false);
     setIsLoading(false)
   }
 }
//--------------------handle closed----------
const handleClose = () =>{
  SetisDelete(false)
}
//------------End---------
//-------------------Handle placed order------
 
 const onLab = (patientID,PatientName,age,sex) =>{
    setLgShow(true)
    setformData((prevData) => ({
      ...prevData,
      PatientID: patientID
    }));
    SetModalHeaderData( (prevData) =>({
      ...prevData,
      PatientName: PatientName,
      age:age,
      Sex:sex,
    }))

 }


//-----------------use call back ----------
const handleOrderFormSubmit = useCallback((orderFormData) => {
  if (orderFormData) {
    setisLabOrder( true);
    setlabOrderData(orderFormData);
    
  }else{
    toast.warning("no data has been selected")
  }
}, []);
const Submit = async ()  =>{
  setIsLoading(true);
  try {
    if(LabOrderData.length > 0){
      const response = await PatientRegistration_LabOrders(formData, LabOrderData);
      if (response) {
        toast.success('Action successfully');
        setLgShow(false)
      }
    }else{
      toast.warning("Please add test")
    }
  } catch (error) {
    toast.error('Something went wrong while adding patient');
  } finally {
    setIsLoading(false);
  }
}
//-------end--------
//-----------Data table implementation--------------------
const column = [
  {
    name: "Patient Name",
    selector: (row) => row.patientName,
    cellExport: (row) => row.patientName,
    sortable: true,
    cell: (row) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span onClick={() => NavigatetoProfile(row.patientID)}  style={{ cursor: "pointer", color: "#0d6efd", fontWeight: "bold" }}>
          {row.patientName.toUpperCase()}
        </span>
        <span style={{ fontSize: "12px", color: "gray" }}>
          {row.age} years | {row.sex}
        </span>
      </div>
    ),
  },
  {
    name: "Referred By",
    selector: (row) => row.referredBy,
    cellExport: (row) => row.referredBy,

    sortable: true,
  },
  {
    name: "Contact Number",
    selector: (row) => row.contactNumber,
    cellExport: (row) => row.contactNumber,
    sortable: true,
  },
  {
    name: "Comments",
    selector: (row) => row.comments,
    cellExport: (row) => row.comments,
    sortable: true,
  },
  {
    name: "Address",
    selector: (row) => row.address,
    cellExport: (row) => row.address,
    sortable: true,
  },
  {
    name: "Actions",
    button: true, 
    width: "160px",
    cell: (row) => (
      <div>
           <Button variant="contained" type="button" className="Delete-Action-Button" onClick={() => handleDelete(row.patientID)}>Delete</Button>
           <Button variant="contained" type="button" className="Action_Button" onClick={() => onLab(row.patientID, row.patientName, row.age, row.sex)}> Add order </Button>
           
      </div>
    ),
  },
];
const data = PatientData;
const tableData = {
  column,
  data: data,
};
const customStyles = {
  rows: {
    style: {
      borderBottom: '1px solid #ccc', // Row border
    },
  },
  cells: {
    style: {
      // borderRight: '1px solid #ccc', // Column border
      fontSize:'12px'
    },
  },
  headCells: {
    style: {
      borderTop:'1px solid #ccc',
      // borderRight: '1px solid #ccc', // Column border in header
      backgroundColor: 'white',
    },
  },
};

return (
    <>
{isLoading && <SpinnerModal isLoading={true} />}
    <div className='container-fluid Container2'>
    <div className='Common-min-height pl-child-div common-box-shadow common-bg-white common-br-3px'>
      <div>
      <DataTableExtensions  columns={tableData.column}
            data={tableData.data} export={true} print={true}   >
          <DataTable
            pagination
            fixedHeader
            fixedHeaderScrollHeight="80vh"
            highlightOnHover
            pointerOnHover
            responsive
            striped
            customStyles={customStyles}
            onRowClicked={(row) => NavigatetoProfile(row.patientID)}
          />
        </DataTableExtensions>
      </div>
   
            </div>
            <div>
                <Dialog PaperProps={{ style: { borderRadius:'3px',padding:'10px'},}} open={isDelete} keepMounted onClose={handleClose} className='common-modal'  TransitionComponent={Transition}>
                  <div style={{textAlign:'end'}}>
                    <button className='common-cross-button' onClick={handleClose}>
                      <CloseRoundedIcon />
                    </button>
                  </div>
                        <DialogTitle style={{textAlign:'center'}} className='common-FontFamily'>
                          <CancelOutlinedIcon style={{fontSize:'100px',color:'red'}}/><br></br>
                          <h4>Are you sure!</h4>
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-slide-description">
                            <span>  Do you really want to delete these record?</span><br></br>
                            <span> This proccess cannot be undone.</span> 
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions sx={{textAlign:'center',display:'block'}}>
                         <Button  onClick={handleClose} variant="contained" type='button' className=" dialog-Cancel-button">Cancel</Button>
                         <Button   onClick={DeletePatients} variant="contained" type='button' className=" dialog-Delete-button">Delete</Button>
                          
                        </DialogActions>
                </Dialog>
            </div>  

          <div>
            <Modal size="xl" show={lgShow}  dialogClassName="modal-90w" aria-labelledby="example-custom-modal-styling-title">
              <Modal.Header className='d-flex align-center justify-content-between'>
                <Modal.Title className='d-flex align-center'>
                  <h4 className='common-FontFamily'>{ModalHeadrData?.PatientName?.toUpperCase()}</h4>
                  <p className='common-FontFamily common-FontSize-small'>{ModalHeadrData.age} Year | {ModalHeadrData.Sex}</p>
                </Modal.Title>
                   <div>
                     <button className="Model-Cross" onClick={() => setLgShow(false)}> <CloseRoundedIcon  /></button>
                  </div>
              </Modal.Header>
              <Modal.Body>
                <LabOrder onSubmit={handleOrderFormSubmit}/>
                <Button style={{marginTop:'20px',float:'inline-end'}} onClick={Submit}  variant="contained" type='button' className="common-button">Save</Button>           
              </Modal.Body>
            </Modal>
         </div>
        </div>
        </>
        
    )
}
export default PatientList;