import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { AddUsers,GetSingleUser} from "../Services/Authservices";
import '../UI/SignUp.css';
import '../UI/TestEntrymodal.css'
import {TextField, MenuItem ,Button} from '@mui/material';
import SpinnerModal from "../Spinner/SpinnerModal";
import { toast } from "react-toastify";

const  SignUp = ({UserID}) => {
    const [isLoading, setIsLoading] = useState(false);
    
    const [UserDatas,SetUserData] = useState();
    const[formData,setformData] = useState({
        FirstName:'',
        LastName:'',
        Email :'',
        PhoneNumber:'',
        Password:'',
        user_Role:'',
        LaboratoryID : sessionStorage.getItem('LabID'),
    });
    const onchange = (e) =>{
        const {name,value} = e.target;
        setformData({
            ...formData,
            [name]:value,
        }) 
    }
   const onsubmit = async(e) =>{
    e.preventDefault();
    setIsLoading(true)
    try{
        const response = await   AddUsers(formData)
        if(response){
            toast.success("Data saved successfully");
        }
    }
    catch(error){
        console.log(error)
    }
    finally{
        setIsLoading(false)
    }
   } 

   const Role = [
    {
      value: 'Admin',
      label: 'Admin',
    },
    {
      value: 'User',
      label: 'User',
    },
   
  ];

  useEffect(()=>{
    EditUser();
  },[UserID])
  const EditUser =async () =>{
    if(UserID){
        try{
            setIsLoading(true)
            const LabID = sessionStorage.getItem('LabID');
            const report = await GetSingleUser(LabID,UserID);
            SetUserData(report);
            console.log("reprt",report)
            setformData( {
                FirstName:report.firstName? report.firstName:'',
                LastName:report.lastName?report.lastName:'',
                Email :report.email?report.email:'',
                PhoneNumber:report.phoneNumber?report.phoneNumber:'',
                Password:report.password?report.password:'',
                user_Role:report.user_Role?report.user_Role:'',
                })
        }catch (error){
            setIsLoading(false)
          console.log("error",error)
        }finally{
            setIsLoading(false)
        }
       
      }
   
  }
  return (
    <>
    {isLoading && <SpinnerModal isLoading={true} />}
   <div className='container-fluid pt-1'>
       <form onSubmit={onsubmit}>
           <div className='row pt-1'>
                <div  className='col-4 mt-2'>
                   <label className='common-Labels'>First Name <span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                              label='First Name'
                              id='FirstName'
                              name='FirstName'
                              value={formData.FirstName}
                              required
                              onChange={onchange} />
                  </div>
                  
                <div  className='col-4 mt-2'>
                       <label className='common-Labels'>Last tName <span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                              label='Last tName'
                              id='LastName'
                              name='LastName'
                              value={formData.LastName}
                              required
                              onChange={onchange}/>
                  </div>
                
                <div  className='col-4 mt-2'>
                <label className='common-Labels'>Phone Number <span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                               label='PhoneNumber'
                              id='PhoneNumber'
                              name="PhoneNumber"
                              value={formData.PhoneNumber}
                              required
                              onChange={onchange} />
                </div>
                <div  className='col-4 mt-2'>
                   <label className='common-Labels'>Email<span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                               label='Email'
                              id='Email'
                              name='Email'
                              value={formData.Email}
                              required
                              onChange={onchange} />
                </div>
                <div  className='col-4 mt-2'>
                    <label className='common-Labels'>Password <span className='common-Asterisk'>*</span></label>
                          <input
                              className="form-control common-Input"
                              label='Password'
                              id='Password'
                              name='Password'
                              value={formData.Password}
                              onChange={onchange} />
                  </div>
                <div  className='col-4 mt-2'>
                     <label className='common-Labels'>User Role <span className='common-Asterisk'>*</span></label>
                      <select className="form-select  common-Input"
                            label='User Role'
                            id='user_Role'
                            name='user_Role'
                            required
                            onChange={onchange}
                            >  
                            {Role.map((option) => (
                            <option key={option.value} value={option.value}>
                            {option.label}
                            </option>
                            ))}
                        </select>
                </div>
            </div>
               <div className='row pt-4 mb-2'>
                 <div  className='col-3'>
                     
                     <Button type='submit'  variant="contained"  className="common-button">Save</Button>
                 </div>
               </div>
       </form>
   </div>
   </>
  );
};

export default  SignUp
