import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import DailyReports from './DailyReports';
import MonthlyReports from './MonthlyReports';

// Example components for each tab
const Tab1Content = () => <div><DailyReports /></div>;
const Tab2Content = () => <div><MonthlyReports /></div>;
const Tab3Content = () => <div>Content for Tab 3</div>;

const Reports = () => {
  // State to track the active tab
  const [activeTab, setActiveTab] = useState(1);

  // Function to change the active tab
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className="container-fluid Container2">
      <div className='common-box-shadow common-bg-white common-br-3px'>
      {/* Tab Navigation */}
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 1 ? 'active' : 'tab'}`}
            href="#"
            onClick={() => handleTabClick(1)}
          >
            Daily Report
          </a>
        </li>
        <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 2 ? 'active' : ''}`}
            href="#"
            onClick={() => handleTabClick(2)}
          >
            Monthly Report
          </a>
        </li>
        {/* <li className="nav-item">
          <a
            className={`nav-link ${activeTab === 3 ? 'Activ-tab' : ''}`}
            href="#"
            onClick={() => handleTabClick(3)}
          >
            Tab 3
          </a>
        </li> */}
      </ul>

      {/* Tab Content */}
      <div className="tab-content">
        <div className={`tab-pane fade ${activeTab === 1 ? 'show active' : ''}`}>
          <Tab1Content />
        </div>
        <div className={`tab-pane fade ${activeTab === 2 ? 'show active' : ''}`}>
          <Tab2Content />
        </div>
        {/* <div className={`tab-pane fade ${activeTab === 3 ? 'show active' : ''}`}>
          <Tab3Content />
        </div> */}
      </div>
      </div>
    </div>
  );
};

export default Reports;
