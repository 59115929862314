import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
import moment from 'moment';
import { getpendingtest } from '../../Services/Dashboard';
import { useNavigate } from 'react-router-dom';
import SpinnerModal from '../../Spinner/SpinnerModal';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import './PendingTests.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2c6bd7',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: '#ffffff',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const PendingTest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formdata, setformdata] = useState([]);
  const [activeTab, setActiveTab] = useState(''); 
  const [groupedTests, setGroupedTests] = useState({});
  const navigate = useNavigate();

  // Fetch the pending tests
  const getpendingtests = async () => {
    try {
      setIsLoading(true);
      const LabID = sessionStorage.getItem('LabID');
      const Response = await getpendingtest(LabID);
      const sortedResponse = Response?.sort((a, b) => b.orderID - a.orderID); // Sorting in descending order
      setActiveTab(sortedResponse[0]?.orderID);
      setformdata(Response);
      
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getpendingtests();
  }, []);

  // Function to navigate to Patient Profile
  const NavigatetoProfile = (id) => {
    navigate(`/Home/PatientProfile/${id}`);
  };

  useEffect(() => {
    const groupedTests = formdata.reduce((acc, test) => {
      if (!acc[test.orderID]) {
          acc[test.orderID] = [];
      }
      acc[test.orderID].push(test);
     // acc[test.orderID].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return acc;
    }, {});
     // Sort orderIDs in descending order
  const sortedOrderIDs = Object.keys(groupedTests).sort((a, b) => Number(b) - Number(a));

  // Set the first orderID as the active tab
  if (sortedOrderIDs.length > 0) {
    setActiveTab(sortedOrderIDs[0]);
  }
    setGroupedTests(groupedTests);
  }, [formdata]);  


  // Handle tab change
  const handleTabClick = (orderID) => {
    setActiveTab(orderID);
  };

  return (
    <>
      {isLoading && <SpinnerModal isLoading={true} />}
      <div className=" container-fluid Container2">
        <div>
          <div className="Common-min-height  p-2">
            <div className='ORD-label'>
              <h5 className='common-FontWeight common-FontFamily'>Pending Orders</h5>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', height: '100%',gap:'15px' }}>
              {/* Left side for tabs */}
              <div className='vertical-parent common-box-shadow common-bg-white'>
                <div className="vertical-tabs common-FontSize-small">
                  <div>
                    <span>Orders</span>
                    <hr style={{margin:0}}></hr>
                  </div>
                  <div className="nav flex-column nav-pills" role="tablist">
                  {Object.keys(groupedTests)
                  .sort((a, b) => Number(b) - Number(a)) // Sort orderIDs in descending order
                  .map((orderID) => {
                    const Data = groupedTests[orderID]; // Get the data for the current orderID
                    const uniqueCategories = [...new Set(Data.map(item => item.categorY_VALUE))]; // Get unique category values

    return (
      <a
        key={orderID}
        className={`nav-link ${activeTab === orderID ? 'PO-tab-active' : 'PO-tab'}`}
        onClick={() => handleTabClick(orderID)} // Set the active tab on click
        style={{ cursor: 'pointer' }}
      >
        {/* Display the created date and status */}
        <span>{moment(Data[0]?.createdAt).format('DD/MM/YY')} | Order Pending</span>
        <br />

        {/* Display the patient name */}
        <span>{Data[0]?.patientName}</span>
        <br />

        {/* Display the unique categories */}
        {uniqueCategories.map((category, index) => (
          <span key={index}>
            {index > 0 && ','} {/* Add separator if not the first category */}
            {category}
          </span>
        ))}
      </a>
    );
  })}

                  </div>
                </div>
              </div>

              {/* Right side for the content of selected tab */}
              <div className="Tabs-content common-bg-white common-box-shadow" >
                {activeTab && (
                  <table className='table common-FontSize-small '>
                    <thead>
                      <tr>
                        <td className='POR-th common-FontSize-small'>Test Name</td>
                        <td className='POR-th common-FontSize-small'>Patient Name</td>
                        <td className='POR-th common-FontSize-small'>Date</td>
                      </tr>
                    </thead>
                    <tbody>
                      {groupedTests[activeTab]?.map((data, index) => (
                        <tr key={index}>
                          <td className='common-Link common-FontSize-small' onClick={() => NavigatetoProfile(data.patientID)}>{data.testName}</td>
                          <td className='common-Link common-FontSize-small'  onClick={() => NavigatetoProfile(data.patientID)}>{data.patientName}</td>
                          <td className='common-Link common-FontSize-small'>{moment(data.createdAt).format('DD/MM/YYYY')}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingTest;
